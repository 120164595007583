import React from 'react';
import { graphql } from 'gatsby';

import { Content } from '../../content/v2/solutions';
import { UqSolutionsPage } from './_solutions-page';

// @todo *rename* [epanulla]: Consider renaming the page path to new name
export default function ProductPage(props) {
  return (
    <UqSolutionsPage
      {...Content.product}
      {...props.data}
    />
  );
}

export const pageQuery = graphql`
  query {
    customerSpotlight: contentfulContentGroup(slug: {eq: "solutions-product-customer-spotlight"}) {
      content {
        ... on ContentfulCustomer {
          logo: spotlightLogo {
            svg {
              content: originalContent
            }
          }
        }
        ... on ContentfulFigureRow {
          actions {
            href
            label
            type
          }
          description {
            description
          }
          image {
            url
          }
          title
        }
      }
    }
    hero: contentfulHero(pageId: {eq: "solutions-product"}) {
      media {
        url
      }
      subtitle
      title
    }
    figures: allContentfulFigureRow(
      filter: {pageId: {eq: "solutions-product"}}
      sort: {order: ASC, fields: order}
    ) {
      nodes {
        actions {
          href
          label
        }
        description {
          description
        }
        image {
          gatsbyImageData(
            formats: [AVIF, WEBP]
            placeholder: BLURRED
            width: 520
          )
          url
        }
        title
      }
    }
    measureQuality: contentfulFigureRow(pageId: {eq: "*-measure-quality"}) {
      actions {
        href
        label
        metadata {
          variant
        }
        type
      }
      description {
        description
      }
      image {
        url
      }
      title
    }
    valueProps: contentfulContentGroup(slug: {eq: "solutions-product-value-props"}) {
      content {
        ... on ContentfulTile {
          desc {
            desc
          }
          icon {
            svg {
              content: originalContent
            }
          }
          title
        }
      }
      title
    }
  }
`;
